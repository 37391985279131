<template>
  <UiPopup
    :model-value="modelValue"
    :title="$t('verify_deal')"
    :primary-button-text="$t('save')"
    :secondary-button-text="$t('close')"
    :description="$t('verify_deal_description')"
    :loading
    @update:model-value="modelValue = false"
    @confirm="updateStatus"
  >
    <div class="mt-4 flex flex-col gap-4">
      <UiInputSelect
        id="statuses"
        v-model="data.status"
        name="statuses"
        :items="statuses"
        placeholder="Verification status"
        :error="useGetFieldErrors(v$, ['status'])"
      />
      <UiInputTextarea
        v-if="
          data.status === statuses.find((item) => item.code === FINANCE_DEAL_VERIFICATION_STATUSES.WAIT_FOR_ACTION)?.id
        "
        id="note"
        v-model="data.note"
        name="note"
        placeholder="Description"
        :start-rows="2"
        label="Provide the description"
      />
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import type { Deal, InputItem, LibraryItem } from '@/types'
import { CUSTOM_EVENTS, FINANCE_DEAL_VERIFICATION_STATUSES } from '~/constants'
import { useUiStore } from '~/store/ui'

const emits = defineEmits(['update:modelValue', 'input'])

const modelValue = defineModel<boolean>({ required: true })

const uiStore = useUiStore()

type Props = {
  deal: Deal
}

const props = defineProps<Props>()

const loading = ref(true)
const statuses = ref<InputItem[] | LibraryItem[]>([])
const data = ref({
  status: undefined,
  note: '',
})

onNuxtReady(async () => {
  try {
    const response = await useFinanceDealVerificationsStatuses()
    statuses.value =
      response
        .filter((item) => item.code !== FINANCE_DEAL_VERIFICATION_STATUSES.NEED_VERIFICATION)
        .map((item) => ({
          value: item.id,
          text: item.name,
          ...item,
        })) || []
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
})

const rules = computed(() => ({
  status: { required: helpers.withMessage('The status is required', required) },
}))

const v$ = useVuelidate(rules, data)

const updateStatus = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) return

  loading.value = true
  try {
    await useUpdateDealVerificationStatus(props.deal.id, data.value.status!, data.value.note)
    uiStore.showSnackBanner()
    document.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.REFRESH_DEAL_DATA))
    modelValue.value = false
    emits('input')
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped></style>
